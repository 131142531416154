<template>
  <div id="introduce">
    <div class="baner"></div>
    <div class="box">
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">公司简介</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-text flex">
          <div class="box-li-text-img"></div>
          <div class="box-li-text-text hui333 fs18">
            杭州聚达物联科技有限公司，专注于智慧社区生活服务领域，秉持着“产品为核心，以技术为桥梁，以品质为保障”的理念，以“聚达之家”APP
            为基石，提供管家式贴心服务，畅享智慧化人居体验，构建便捷邻里生活圈，创新高效社区管理模式，立足科技心向未来，探索社区数字化生活新方式。
          </div>
        </div>
      </div>
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">荣誉资质</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-text flex JCcenter FWw">
          <div class="box-li-text-box box-li-text-boxbg1"></div>
          <div class="box-li-text-box box-li-text-boxbg2"></div>
          <div class="box-li-text-box box-li-text-boxbg3"></div>
          <div class="box-li-text-box box-li-text-boxbg4"></div>
        </div>
      </div>
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">公司实景</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-text flex JCcenter FWw">
          <div class="box-li-text-box box-li-text-boxbg5"></div>
          <div class="box-li-text-box box-li-text-boxbg6"></div>
          <div class="box-li-text-box box-li-text-boxbg7"></div>
          <div class="box-li-text-box box-li-text-boxbg8"></div>
        </div>
      </div>
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">合作伙伴</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="bix-li-huoban"></div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
export default {
  name: "introduce",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="less">
#introduce {
  .baner {
    width: 1920px;
    height: 576px;
    background-image: url(~@/assets/introduce1.png);
    background-size: 100% 100%;
  }
  .box {
    width: 1200px;
    margin: 0 360px;
    .bix-li {
      width: 1200px;
      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }
      .box-li-text {
        width: 1200px;
        margin-top: 80px;
        .box-li-text-img {
          width: 400px;
          height: 250px;
          margin-right: 66px;
          background-image: url(~@/assets/introduce2.png);
          background-size: 100% 100%;
        }
        .box-li-text-text {
          width: 694px;
          line-height: 48px;
        }
        .box-li-text-box {
          width: 280px;
          height: 370px;
          margin-right: 26px;
        }
        .box-li-text-box:last-child {
          margin-right: 0;
        }
        .box-li-text-boxbg1 {
          background-image: url(~@/assets/introduce3.png);
          background-size: 100% 100%;
        }
        .box-li-text-boxbg2 {
          background-image: url(~@/assets/introduce4.png);
          background-size: 100% 100%;
        }
        .box-li-text-boxbg3 {
          background-image: url(~@/assets/introduce5.png);
          background-size: 100% 100%;
        }
        .box-li-text-boxbg4 {
          background-image: url(~@/assets/introduce6.png);
          background-size: 100% 100%;
        }
        .box-li-text-boxbg5 {
          background-image: url(~@/assets/introduce7.png);
          background-size: 100% 100%;
        }
        .box-li-text-boxbg6 {
          background-image: url(~@/assets/introduce8.png);
          background-size: 100% 100%;
        }
        .box-li-text-boxbg7 {
          background-image: url(~@/assets/introduce9.png);
          background-size: 100% 100%;
        }
        .box-li-text-boxbg8 {
          background-image: url(~@/assets/introduce10.png);
          background-size: 100% 100%;
        }
      }
      .bix-li-huoban {
        width: 1200px;
        margin-top: 64px;
        margin-bottom: 123px;
        height: 404px;
        background-image: url(~@/assets/huoban.png);
        background-size: 100% 100%;
      }
    }
  }
}
</style>